

.imageShow,
.plusShow {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    cursor: pointer;
    /* position: absolute; */
}

.plusShow {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border: none;
    background: #c4c4c4;
    color: #000;
  }

.circle_plus{
    width: 70px;
    height: 70px;
}