.product-img-container {
  background: var(--glass);
  width: 335px;
}
.product-img-container > img {
  max-width: 100%;
  height: auto;
  max-height: 335px;
  vertical-align: middle;
}
@media only screen and (max-width: 640px) {
  .product-title {
    order: -9999;
  }
  .product-img-container {
    max-width: 335px;
    width: auto;
  }
}
