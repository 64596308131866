@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree&display=swap');


.body{
    display : flex;
    justify-content: center;
}

.form{
    display : flex;
    flex-direction : column;
    justify-content: center;
    flex-basis : 1200px;
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
    border-radius: 20px;
    margin : 90px 30px 30px 30px;
}

#header{
    text-align: center;
    margin : 20px ;
    height : 25.49px;

    /* TH/H4 */

    font-family: CHULALONGKORN;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 42px;
    align-items: center;
    color: #FFFFFF;

}
.row1{
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: auto minmax(250px,300px) auto minmax(250px, 263px);
}

.row2{
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: auto minmax(130px,230px) auto minmax(260px, 285px);
}

.row3{
    display : grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: auto minmax(70px, 144px) auto minmax(300px, 382px);
}

.row4{
    display : grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: auto minmax(300px, 400px) auto 120px;
}

.row5{
    display : grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: auto minmax(90px, 130px) auto minmax(105px, 160px) auto minmax(75px, 120px);
}

input:invalid ~ span{
    display : block;
}

#option{
    background-color :#9B2C33;
}

@media (min-width : 600px){
    .collapsible{
        display: none;
    }
    
}
@media (max-width : 600px) {
    .form{
        backdrop-filter: blur(50px);
        border-radius: 18px;
        margin : 50px 24px;
    }
    .row1{
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: minmax(0px, 278px);
        grid-template-rows : 1fr 1fr;
    }
    
    .row2{
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: minmax(0px, 278px);
        grid-template-rows : 1fr 1fr;
    }
    
    .row3{
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: minmax(0px, 278px);
        grid-template-rows : 1fr 1fr;
    }
    .row4{
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: minmax(0px, 278px);
        grid-template-rows : 1fr 1fr;
    }
    .row5{
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: minmax(0px, 278px);
        grid-template-rows : 1fr 1fr 1fr;
    }
    
    input:invalid ~ span{
        display : block;
    }
    .conpc{
        display: none;
    }
    .collapsed-icon{
        color : white;
        height : 24px;
        width : 24px;
        align-items: flex;
        margin : 10px 130px;
        vertical-align: middle;
    }

    #option{
        background-color :#9B2C33;
    }
}
