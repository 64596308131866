@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree&display=swap");

.logincon {
  background: rgba(138, 32, 43, 0.7);
  background-image: url("../../assets/images/background.svg");
  background-size: cover;
  background-repeat: repeat;
}

.loginbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 473px;
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(30px);
  border-radius: 10px;
  margin: 11rem 30px;
}

.loginform {
  display: grid;
  grid-template-columns: minmax(0px, 430px);
  grid-template-rows: 18px 1fr 18px 1fr 20px 1fr;
  align-items: center;

  font-family: CHULALONGKORN;
}

.header {
  display: flex;
  align-items: center;
  align-self: start;
  margin: 30px 30px 5px 30px;
  height: 54px;
  /* TH/H4 */

  font-family: CHULALONGKORN;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  color: #ffffff;
}

.label {
  margin: 0px 10px;
  height: 18px;
  font-family: Bai Jamjuree;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: white;
}

.input {
  margin: 5px 10px;
  padding: 1px 10px;
  height: 30px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Bai Jamjuree;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: white;
}
input:focus {
  outline: none;
}

.loginwith {
  display: grid;
  grid-template-columns: minmax(0px, 160px) auto minmax(0px, 160px);
  grid-template-rows: 1fr;
  margin: 10px;
  font-family: Bai Jamjuree;
}

.line {
  border: 1px solid #ffffff;
  background-color: #ffffff;
  margin: 5px;
}

.loginbutton {
  display: grid;
  grid-template-columns: 1fr minmax(10px, 16px) 1fr;
  grid-template-rows: 1fr;
  margin: 0px 5px 20px 5px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  padding: 7px 4px;
}

.fb-icon {
  background-color: white;
  color: #395185;
}
