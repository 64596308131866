.switch button {
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
  height: auto;
  max-height: 40px;
  width: 100%;
}
.switch > :first-child {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.switch > :last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.switch {
  max-width: 494px;
  border-radius: 5px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.15) 100%);
  color: var(--crimson);
}
#selected-tab {
  background: var(--gm-color);
  color: var(--white-400);
  border-radius: 5px;
  transition: 0.3s;
}
